.wp-block-timeline {
    section.reveal-timeline {
        .content,
        .media {
            @apply opacity-50 transition-opacity duration-500;
        }
        &.active {
            .content,
            .media {
                @apply opacity-100;
            }
        }
    }
    &.normal-layout {
        section {
            @apply lg:py-xl-4 py-xl;
            &.timeline-last-item {
                .dot {
                    @apply top-[20px] lg:-top-[10px] !important;
                }
            }
        }
        .dot,
        .dashed-line-container,
        .floating-label-wrapper {
            @apply lg:top-xl-4 top-[38px];
        }
    }
    &.one-sided-layout {
        @apply lg:pl-xl-2;
        section {
            @apply grid-cols-1 py-xl !important;
            .content,
            .media {
                @apply pr-0 pl-xl-3 !important;
            }
            .media {
                @apply order-2 !important;
            }
            .content {
                @apply order-1 mb-xl !important;
            }
            .dot,
            .dashed-line-container {
                @apply left-0 !important;
            }
            &.timeline-last-item {
                @apply top-xl !important;
                & > div {
                    @apply basis-full text-left items-start !important;
                    .btn {
                        @apply self-start !important;
                    }
                }
                .dot {
                    @apply top-0 lg:-top-[10px] !important;
                }
            }
        }
        .floating-label-wrapper {
            .sticky {
                @apply justify-start !important;
                transform: translate(-45px, 0);
            }
        }
        .dot,
        .dashed-line-container,
        .floating-label-wrapper {
            @apply top-[38px] lg:top-xl;
        }
    }
    &.condensed-layout {
        section {
            @apply py-md lg:py-0;
            .content {
                @apply mb-0 !important;
            }
            &.timeline-last-item {
                .dot {
                    @apply top-sm lg:-top-md !important;
                }
            }
        }
        .dot,
        .dashed-line-container,
        .floating-label-wrapper {
            @apply top-[20px] lg:top-xl;
        }
    }
    &.condensed-layout.one-sided-layout {
        section {
            &.timeline-last-item {
                @apply lg:py-xl-3 !important;
                .dot {
                    @apply lg:-top-[20px] !important;
                }
            }
        }
        .dot,
        .dashed-line-container,
        .floating-label-wrapper {
            @apply top-[38px] lg:top-xl;
        }
    }
    .dashed-line-container {
        background-image: url('data:image/svg+xml,<svg width="2" height="500" version="1.1" xmlns="http://www.w3.org/2000/svg"><line stroke="white" stroke-dasharray="5, 20" x1="0" y1="1" x2="1" y2="600" style="stroke-width: 2px;"></line></svg>');
        background-repeat: repeat-y;
    }
    &.hide-last-item-layout {
        section:last-child {
            .dashed-line-container {
                @apply hidden;
            }
        }
    }
}
