.glossary-tooltip {
    @apply flex flex-col gap-y-sm bottom-full mt-md left-0 border-1 border-slate-8 p-md absolute w-auto min-w-[400px] whitespace-normal rounded-sm bg-black text-left text-14 font-normal leading-normal text-white z-10 !important;
    .title {
        @apply text-16 font-bold !important;
    }
    .content {
        @apply text-14 text-truncate-3 !important;
    }
    .link {
        a {
            @apply text-14 !important;
        }
    }
}
