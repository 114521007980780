.wp-block-tabs {
    .tabs-container {
        .tabs {
            .tab-item {
                a {
                    @apply border-b-1 top-[1px];
                }
            }
        }
        &.dark {
            .border-container {
                @apply border-slate-8;
            }
            .tabs {
                .tab-item {
                    a {
                        @apply border-slate-8;
                        .title {
                            @apply text-slate-11;
                        }
                        .subtitle {
                            @apply text-slate-11;
                        }
                    }
                    &.active,
                    &:hover {
                        a {
                            @apply border-b-black;
                            background: linear-gradient(360deg, rgba(25, 25, 25, 0) 0%, #191919 100%);
                            .subtitle {
                                @apply text-white;
                            }
                        }
                    }
                }
            }
        }
        &.light {
            .border-container {
                @apply border-slate-2;
            }
            .tabs {
                .tab-item {
                    a {
                        @apply border-slate-2;
                        .title {
                            @apply text-slate-8;
                        }
                        .subtitle {
                            @apply text-slate-8;
                        }
                    }
                    &.active {
                        a {
                            @apply border-b-white;
                            .subtitle {
                                @apply text-black;
                            }
                        }
                    }
                }
            }
        }
    }
}
