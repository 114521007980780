.wp-block-glossary {
    .navigation {
        .sticky {
            @apply top-[132px];
        }
    }
    .search-result {
        @apply scroll-mt-[116px];
        .content-rendered {
            p {
                &:last-of-type {
                    @apply mb-0;
                }
            }
        }
    }

    .sticky {
        .back-to-top {
            @apply opacity-0 transition-opacity duration-500 ease-in-out;
        }
        &.is-sticky {
            .back-to-top {
                @apply opacity-100;
            }
        }
    }
}

body.logged-in .wp-block-glossary {
    .navigation {
        .sticky {
            @apply top-[164px];
        }
    }
    .search-result {
        @apply scroll-mt-[120px];
    }
}
