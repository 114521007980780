html {
    scroll-behavior: smooth;
}

.blur-background {
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        backdrop-filter: blur(5px);
        z-index: -1;
        background-color: rgba(17, 17, 19, 0.9);
    }
}

header {
    @apply relative lg:h-[100px] h-[75px];

    &.scrolling {
        .hover-wrapper {
            &::before {
                @apply translate-x-[-100px] translate-y-0 rotate-3 duration-500;
            }
        }
    }

    nav {
        .dropdown-wrapper {
            perspective: 2000px;

            .dropdown-backdrop {
                transform-origin: top center;
                transform: translate3d(-50%, 0, 0) scale(1);
                opacity: 0;
            }

            .hide-dropdown {
                @apply opacity-0 invisible transition-all duration-300;
            }
            .show-dropdown {
                @apply opacity-100 visible transition-all duration-300;
            }
            .hide-dropdown-backdrop {
                @apply invisible animate-hingeup opacity-0;
            }
            .show-dropdown-backdrop {
                @apply visible animate-hingedown opacity-100;
            }
        }
    }
}

.show-mobile-menu {
    @apply translate-y-0 transition-all !important;
}

.hide-mobile-menu {
    @apply -translate-y-full transition-all !important;
}

.burger-menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    @apply bg-transparent border-none cursor-pointer flex items-center justify-center p-0 w-[40px] h-[40px];
    svg {
        @apply h-full;
    }
    .line {
        @apply stroke-white;
        fill: none;
        stroke-width: 6;
        transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    .line1 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
    }
    .line2 {
        stroke-dasharray: 60 60;
        stroke-width: 6;
    }
    .line3 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
    }
    &.opened {
        .line1 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
            stroke-width: 6;
        }
        .line2 {
            stroke-dasharray: 1 60;
            stroke-dashoffset: -30;
            stroke-width: 6;
        }
        .line3 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
            stroke-width: 6;
        }
    }
}
