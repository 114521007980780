.wp-block-comparison-table,
.wp-block-comparison-table-clone {
    .table-wrapper {
        &.columns-2_col {
            .row {
                .normal-cell {
                    @apply w-1/2 lg:w-1/4 !important;
                }
                .large-cell {
                    @apply w-1/2 lg:w-2/4 !important;
                }
            }
        }
        &.columns-3_col {
            .row {
                .normal-cell {
                    @apply w-1/2 lg:w-1/5 !important;
                }
                .large-cell {
                    @apply w-1/2 lg:w-2/5 !important;
                }
            }
        }
        &.columns-4_col {
            .row {
                .large-cell {
                    @apply w-1/2 lg:w-2/6  !important;
                }
                .normal-cell {
                    @apply w-1/2 lg:w-1/6 !important;
                }
            }
        }
        &.columns-5_col {
            .row {
                .large-cell {
                    @apply w-1/2 lg:w-2/7  !important;
                }
                .normal-cell {
                    @apply w-1/2 lg:w-1/7 !important;
                }
            }
        }
    }
    .row {
        @apply w-full;
        &:hover {
            .cell {
                @apply relative;
                &:after {
                    @apply content-[''] absolute top-0 left-0 w-full h-full bg-slate-8 pointer-events-none opacity-20;
                }
            }
        }

        .normal-cell {
            @apply p-md lg:border-r-0 border-r-1 border-b-1 border-l-1 border-slate-8 w-1/2 lg:w-1/5 text-center text-16 transition-spacing;
        }
        .large-cell {
            @apply p-md border-b-1 border-slate-8 w-1/2 lg:w-2/5 text-left text-16 transition-spacing;
            p:last-of-type {
                @apply mb-0 !important;
            }
        }

        &:last-child {
            .normal-cell,
            .large-cell {
                @apply border-b-0;
            }
        }

        .tooltip {
            *:last-child {
                @apply mb-0;
            }
        }
        .banner {
            bottom: calc(100% + 1px);
        }
        &.clone-top-header {
            .normal-cell,
            .large-cell {
                @apply border-b-1 overflow-hidden;
            }
        }
    }
}
