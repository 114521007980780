.jvm-container {
    .jvm-zoom-btn {
        @apply w-auto h-auto p-md left-lg text-20 bg-slate-2;
        &.jvm-zoomin {
            @apply top-[30px];
        }
        &.jvm-zoomout {
            @apply top-[80px];
        }
    }
    svg {
        path[data-code='border-ma'] {
            stroke-dasharray: 1, 0.5;
            @apply pointer-events-none stroke-black fill-transparent;
        }
        path[data-code='PS'] {
            stroke-width: 0.3;
            stroke-dasharray: 0.5, 0.3;
            @apply stroke-black;
        }
        path.jvm-region {
            @apply transition-all duration-500 ease-in-out;
        }
    }
}
