#wpadminbar {
    @apply fixed !important;
}

body.logged-in {
    .navigation-wrapper {
        @apply top-[46px] md:top-[40px] !important;
    }
    @apply mt-[46px] md:mt-[40px] !important;
}

.ab-top-menu {
    &:not(.ab-top-secondary) li {
        border-right: 1px #474747 solid;
        &:last-of-type {
            border-right: none;
        }
    }
    &.ab-top-secondary {
        li {
            border-left: 1px #474747 solid;
            &:last-of-type {
                border-left: none;
            }
        }
    }
    li {
        strong {
            @apply font-bold !important;
        }
    }
}
