/* .wp-block-group {
    content-visibility: auto;
} */

.wp-block-group,
.wp-block-cover,
.wp-block-columns,
.wp-block-slider {
    @apply max-w-screen-md mx-auto !important;
    &.alignwide {
        @apply xl:max-w-screen-xl max-w-screen-xl mx-auto !important;
    }
    &.alignfull {
        @apply max-w-full mx-auto px-0 !important;
    }
}

body.post-type-wp_block,
body .wp-site-blocks {
    .wp-block-group {
        @apply max-w-full mx-auto !important;
    }
}

.wp-block-group,
.wp-block-cover {
    @apply xl:px-xl;
    .wp-block-cover__inner-container {
        .wp-block-group {
            @apply max-w-screen-xl mx-auto  px-0 !important;
        }
    }
}

main .wp-block-columns {
    @apply px-xl max-xl:flex-wrap max-xl:justify-center;
    margin-block-start: 1rem;
    .wp-block-columns {
        @apply px-0;
    }
    .wp-block-column.flex {
        .wp-block-columns {
            @apply mx-0 !important;
        }
    }
    > .wp-block-column[style*='flex-basis:25%'] {
        @apply max-xl:basis-5/12 max-md:basis-full !important;
    }
    > .wp-block-column[style*='flex-basis:33.33%'] {
        @apply max-xl:basis-7/12 max-md:basis-full !important;
    }
    > .wp-block-column {
        @apply max-md:basis-full !important;
        &.wp-column-empty {
            @apply max-lg:hidden !important;
        }
    }
}

.is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull):not(.wp-block-columns):not(.wp-block-group)) {
    @apply max-w-full mx-auto !important;
}

.is-position-sticky {
    top: calc(0px + var(--wp-admin--admin-bar--position-offset, 0px) + 100px) !important;
    z-index: 1000 !important;
}
