@import 'tailwindcss/base';

@import 'base/grid';
@import 'base/typography';
@import 'base/table';
@import 'base/media';
@import 'base/forms';
@import 'base/navigation';
@import 'base/wordpress';
@import 'base/embeds';
@import 'base/icons';
@import 'base/cookiebanner';

@import 'blocks/quote-block';
@import 'blocks/highligter-box';
@import 'blocks/coverage-table';
@import 'blocks/comparison-table';
@import 'blocks/tabs';
@import 'blocks/pricing-card';
@import 'blocks/rank-math-seo';
@import 'blocks/content-hub';
@import 'blocks/wistia-video';
@import 'blocks/timeline';
@import 'blocks/glossary';

@import 'tailwindcss/components';
@import 'components/toggle-slider';
@import 'components/buttons';
@import 'components/glossary-tooltip';
@import 'components/svg-map';

@import 'tailwindcss/utilities';
@import 'utilities/utilities';
@import 'utilities/spinner';
