.toggle-slider {
    @apply bg-slate-4 rounded-sm p-xs flex flex-row;
    .toggle-slide {
        label {
            &:before {
                @apply invisible bg-slate-8;
            }
            &:after {
                @apply opacity-0 bg-slate-8 transition-all;
            }
            &:before,
            &:after {
                @apply p-sm absolute left-0 top-0 -z-1 h-full w-full rounded-sm content-[''];
            }
            &:hover {
                &:after {
                    @apply opacity-50;
                }
            }
        }
        input {
            @apply hidden;
            &:checked {
                + label {
                    &:before {
                        @apply visible !important;
                    }
                    &:hover {
                        &:after {
                            @apply opacity-0;
                        }
                    }
                }
            }
        }
    }
}
