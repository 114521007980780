.with-list-icon {
    position: relative;
    list-style-type: none !important;
    margin: 0;
    padding: 0px 0px 1px 0px;
    vertical-align: middle;
    &:after {
        background-image: var(--icon-image);
        @apply absolute top-1/2 -left-8 content-[''] bg-no-repeat w-lg h-lg -translate-y-1/2;
    }
    &.with-list-icon-size-12 {
        &:after {
            @apply -left-5 w-[12px] h-[12px];
        }
    }
    &.with-list-icon-size-16 {
        &:after {
            @apply -left-6 w-[16px] h-[16px];
        }
    }
    &.with-list-icon-size-24 {
        &:after {
            @apply -left-8 w-[24px] h-[24px];
        }
    }
}
