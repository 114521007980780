.buttons:not(body) {
    @apply flex flex-row flex-wrap items-start justify-start gap-md;
}

.btn {
    @apply font-bold inline-flex flex-row items-center justify-center self-start justify-self-start focus:outline-none rounded-pill no-underline leading-tight transition duration-300 ease-in-out border-0;

    &:hover:not(.no-hover) {
        @apply cursor-pointer;
    }
    span.text {
        @apply relative flex items-center justify-start whitespace-nowrap p-0;
    }
    &:last-of-type {
        @apply mb-0;
    }
    .icon:empty {
        @apply hidden;
    }
    &.btn-naked {
        @apply bg-transparent !important;
        &:hover:not(.no-hover) {
            @apply bg-transparent !important;
        }
        &.btn-white {
            @apply text-white !important;
            &:hover:not(.no-hover) {
                @apply text-white !important;
            }
        }
        &.btn-black {
            @apply text-black !important;
            &:hover:not(.no-hover) {
                @apply text-black !important;
            }
        }
        .arrow {
            width: 12px;
            height: 12px;
            border-right: 2px solid #c2ffe9;
            border-bottom: 2px solid #c2ffe9;
            position: relative;
            transform: rotate(-45deg);
            margin: 0 16px;
            transition: all 0.3s ease;

            &::before {
                display: block;
                background-color: currentColor;
                width: 3px;
                transform-origin: bottom right;
                height: 2px;
                position: absolute;
                opacity: 0;
                bottom: calc(-2px / 2);
                transform: rotate(45deg);
                transition: all 0.3s ease;
                content: '';
                right: 0;
            }
        }
        &:hover > .arrow {
            transform: rotate(-45deg) translate(4px, 4px);
            @apply border-primary-1;
        }

        &:hover > .arrow::before {
            opacity: 1;
            width: 16px;
            @apply bg-primary-1;
        }
    }
}
