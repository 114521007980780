@layer components {
    .field {
        @apply flex flex-col gap-x-md text-16 relative;

        label {
            @apply ml-0 mb-xs block font-semibold text-16;
        }

        .input {
            select,
            textarea,
            input:not([type='submit']):not(.select2-search__field) {
                @apply w-full px-md py-sm text-16 border border-slate-8 bg-black border-solid text-white;
                @apply rounded-sm !important;
                &:focus,
                &:focus-visible {
                    @apply border-primary-1 outline-none shadow-none !important;
                }
            }

            select {
                @apply max-w-full bg-none !important;
                option {
                    @apply text-white;
                }
            }

            input[type='submit'] {
                @apply hover:cursor-pointer mt-lg;
            }

            input[type='checkbox'],
            input[type='radio'] {
                @apply w-lg h-lg;
                @apply rounded-none !important;
                &:checked {
                    @apply border-slate-8;
                }
            }

            input[type='file'] {
                @apply px-sm;
                &::file-selector-button {
                    @apply btn btn-white btn-sm m-0 rounded-sm shadow-none outline-none border-0 px-sm py-sm mr-sm;
                }
            }

            textarea {
                @apply min-h-150;
            }

            legend {
                @apply italic mb-xs;
            }

            .select2-init {
                @apply opacity-0 max-h-[44px] !important;
            }

            .select2-container {
                @apply text-white outline-0 !important;
                & *:focus:not(ul):not(li) {
                    @apply outline-none;
                }
                .select2-selection {
                    @apply h-auto min-h-[44px] border border-solid border-slate-8 rounded-sm text-white !important;

                    ul.select2-selection__rendered {
                        @apply flex flex-row flex-wrap gap-sm relative w-full pl-md pr-xl-2 py-sm leading-normal text-16  !important;
                        .select2-selection__choice {
                            @apply btn btn-white btn-sm m-0 rounded-xs shadow-none outline-none border-0 pl-0 pr-md py-0 mr-sm flex flex-row-reverse justify-center items-center leading-none h-6;
                            span.select2-selection__choice__remove {
                                @apply mr-0 ml-xs text-center text-black text-20 w-lg !important;
                            }
                        }
                        .select2-search.select2-search--inline {
                            @apply flex-grow mb-0 !important;
                        }
                    }
                    .select2-selection__clear {
                        @apply m-0 order-1 text-20 flex-col justify-center leading-tight hidden !important;
                    }
                    .select2-selection__arrow {
                        @apply hidden !important;
                    }
                    .select2-search--inline .select2-search__field {
                        @apply mt-0 !important;
                    }
                    .select2-selection__choice {
                        @apply m-0 !important;
                    }
                }
                &.select2-container--focus,
                &.select2-container--open {
                    .select2-selection {
                        @apply border-primary-1 !important;
                    }
                    .select2-selection__rendered {
                        @apply border-primary-1 outline-none shadow-none !important;
                    }
                }
                /* top-[32px] max-md:top-[46px] */
                .select2-dropdown {
                    @apply bg-black text-white rounded-sm border-0 border-solid border-slate-8 text-16 p-sm grad-black !important;

                    .select2-search__field {
                        @apply rounded-sm border border-slate-2 bg-black border-solid h-auto outline-none !important;
                    }

                    .select2-results__option--highlighted[aria-selected],
                    .select2-results__option[aria-selected='true'] {
                        @apply bg-transparent text-primary-1;
                    }
                    .select2-results__options,
                    .select2-results__option {
                        @apply list-none pl-sm mb-0;
                    }
                    .select2-results > .select2-results__options {
                        max-height: 400px;
                    }
                }
                .black & {
                    @apply text-black !important;
                    .select2-selection {
                        @apply bg-white !important;
                        .select2-selection__rendered {
                            @apply text-black border-black !important;
                        }
                    }
                    ul.select2-selection__rendered {
                        .select2-selection__choice {
                            @apply btn-black;
                            .select2-selection__choice__remove {
                                @apply text-white;
                            }
                        }
                    }
                }
                .white & {
                    @apply text-white !important;
                    .select2-selection {
                        @apply bg-black !important;
                        .select2-selection__rendered {
                            @apply text-white border-white !important;
                        }
                    }
                    ul.select2-selection__rendered {
                        .select2-selection__choice {
                            @apply btn-white;
                            .select2-selection__choice__remove {
                                @apply text-black;
                            }
                        }
                    }
                }
            }
        }
        &[class*='radio'],
        &[class*='checkbox'] {
            .input {
                ul {
                    @apply p-0 mb-0 flex flex-row flex-wrap gap-md ml-0;
                    li {
                        @apply list-none;
                    }
                }

                label {
                    @apply flex flex-row mx-0 mb-0 font-normal relative;
                    input {
                        @apply opacity-0 absolute top-0 left-0 pointer-events-none;
                        &:checked ~ span::after {
                            @apply block;
                        }
                    }
                    & > span {
                        @apply inline-flex hover:cursor-pointer relative;
                        p {
                            @apply order-1;
                        }
                        &::after {
                            @apply absolute left-[4px] top-[4px] content-[''] w-md h-md bg-cover text-white hidden;
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
                            .white & {
                                @apply text-black;
                                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23000000' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
                            }
                        }
                        &::before {
                            @apply flex-none mr-sm content-[''] w-lg h-lg border border-slate-8 block rounded-xs bg-slate-2;
                            .white & {
                                @apply bg-white;
                            }
                            .black & {
                                @apply bg-black;
                            }
                        }
                    }
                }
            }
            .input-toggles {
                ul {
                    @apply p-0 mb-0 flex flex-row flex-wrap gap-md ml-0;
                    li {
                        @apply list-none;
                    }
                }

                label {
                    @apply flex flex-row mx-0 mb-0 font-normal relative min-w-[75px] justify-center;
                    input {
                        @apply opacity-0 absolute top-0 left-0 pointer-events-none;
                        &:checked ~ span::before {
                            @apply bg-primary-1 -z-1 border-primary-1;
                        }
                        &:checked ~ span {
                            @apply text-white z-20;
                        }
                        &:disabled ~ span::before {
                            @apply opacity-50 cursor-not-allowed;
                        }
                        &:disabled ~ span {
                            @apply text-slate-11 cursor-not-allowed;
                        }
                    }
                    &:hover {
                        span::before {
                            @apply bg-primary-1 border-primary-1 -z-1;
                        }
                        span {
                            @apply text-black z-20;
                        }
                    }
                    & > span {
                        @apply flex hover:cursor-pointer relative p-md text-14 leading-none w-full text-center justify-center font-bold;
                        p {
                            @apply order-1;
                        }
                        &::before {
                            @apply content-[''] absolute top-0 left-0 w-full h-full border-2 border-slate-11 block rounded-pill;
                        }
                    }
                }
            }
            &.checkbox-sm {
                .input {
                    label {
                        @apply text-[13px];
                        & > span {
                            &::after {
                                @apply w-[12px] h-[12px] left-[4px] top-[4px];
                            }
                            &::before {
                                @apply w-[20px] h-[20px];
                            }
                        }
                    }
                }
            }
            &.checkbox-right {
                .input {
                    label {
                        & > span {
                            &::after {
                                @apply absolute right-[4px] left-auto;
                            }
                            &::before {
                                @apply order-2 mr-0 ml-sm;
                            }
                        }
                    }
                }
            }
        }
        &[class*='select'] {
            .input {
                @apply relative;
                select {
                    @apply appearance-none;
                }
                &::before {
                    @apply absolute top-sm right-sm h-xl w-xl content-[''] bg-cover pointer-events-none z-10;
                    background-image: url("data:image/svg+xml,%3Csvg height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' d='M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z'/%3E%3C/svg%3E");
                    .white & {
                        background-image: url("data:image/svg+xml,%3Csvg height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23000000' d='M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z'/%3E%3C/svg%3E");
                    }
                }
            }
        }
        &[class*='phone'] {
            .hs-fieldtype-intl-phone {
                @apply relative;
                select {
                    @apply appearance-none;
                }
                &::before {
                    @apply absolute top-sm right-sm lg:right-1/2 lg:mr-md h-xl w-xl content-[''] bg-cover pointer-events-none z-10;
                    background-image: url("data:image/svg+xml,%3Csvg height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' d='M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z'/%3E%3C/svg%3E");
                    .white & {
                        background-image: url("data:image/svg+xml,%3Csvg height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23000000' d='M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z'/%3E%3C/svg%3E");
                    }
                }
            }
        }
        &[class*='select'].s2 {
            .input {
                &::before {
                    @apply content-none;
                }
                .select2 {
                    &::before {
                        @apply absolute top-sm right-sm h-xl w-xl content-[''] bg-cover pointer-events-none z-10;
                        background-image: url("data:image/svg+xml,%3Csvg height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' d='M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z'/%3E%3C/svg%3E");
                        .white & {
                            background-image: url("data:image/svg+xml,%3Csvg height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23000000' d='M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z'/%3E%3C/svg%3E");
                        }
                    }
                }
            }
        }
        &.select-bg-black {
            .select2-selection {
                @apply bg-black !important;
            }
        }

        &.select-bg-slate-2 {
            .select2-selection {
                @apply bg-slate-2 !important;
            }
        }

        .form-theme-slate-2 & {
            .input {
                select,
                textarea,
                input:not([type='submit']):not(.select2-search__field) {
                    @apply bg-slate-2;
                }
            }
        }
        .form-theme-white & {
            .input {
                select,
                textarea,
                input:not([type='submit']):not(.select2-search__field) {
                    @apply bg-white text-black;
                }
            }
        }
    }
}

.form-theme-white {
    .submitted-message * {
        @apply text-black !important;
    }
}

.form-theme-black,
.form-theme-slate-2 {
    .submitted-message * {
        @apply text-white !important;
    }
}

.submitted-message {
    @apply text-center m-0 font-bold justify-self-center h-full flex flex-col justify-center items-center p-xl-2;
}

form[id*='hsForm'],
form.native-form {
    @apply relative max-w-full w-full text-16 flex flex-col gap-y-md text-white p-xl border-slate-8 rounded-md border overflow-hidden !important;

    fieldset:has(div[style*='display: none']) {
        @apply hidden;
    }

    &.inline {
        @apply flex lg:flex-row gap-x-md flex-wrap !important;
        .field {
            @apply grow;
        }
        .hs_submit {
            @apply grow-0 !important;
        }
        input[type='submit'] {
            @apply mr-0 grow-0 !important;
        }
        .legal-consent-container {
            @apply basis-full w-full !important;
            p {
                @apply text-slate-8 !important;
            }
        }
    }

    &.white {
        @apply bg-slate-12 text-black border-slate-8 !important;
        .hs-richtext {
            p,
            span {
                @apply text-black !important;
            }
        }
    }

    &.naked {
        @apply bg-transparent border-0 p-0 rounded-none !important;
    }

    &.slim {
        @apply gap-y-lg !important;
        .field {
            label {
                @apply ml-0 mb-xs px-xs block font-semibold text-16;
            }

            &:not(.hs-fieldtype-checkbox) {
                & > label {
                    @apply absolute left-md top-[10px] transition-all duration-100 ease-in-out pointer-events-none font-normal z-40 overflow-hidden truncate max-md:max-w-[75%] origin-left bg-transparent;
                }

                &.active {
                    & > label {
                        @apply scale-75 left-0 -translate-y-8 font-normal origin-left max-md:max-w-[100%];
                    }
                    input,
                    select,
                    textarea {
                        @apply border-slate-8;
                        &:focus,
                        &:focus-visible {
                            @apply border-primary-1 outline-none shadow-none !important;
                        }
                    }
                }
                select.has-gradient-background {
                    option {
                        &:first-of-type {
                            @apply text-transparent;
                        }
                    }
                }
            }

            &.hs-fieldtype-textarea {
                & > label {
                    @apply max-w-[90%];
                    text-wrap: pretty;
                }
            }

            input:-webkit-autofill,
            input:-webkit-autofill:focus {
                transition:
                    background-color 600000s 0s,
                    color 600000s 0s;
            }
            input[data-autocompleted] {
                background-color: transparent !important;
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply mb-md text-h5 !important;
    }

    p {
        @apply mb-md;
    }

    a {
        @apply text-primary-1 !important;
    }

    ul:not(.inputs-list) {
        @apply pl-lg flex flex-col gap-sm;
        & > li {
            @apply list-disc;
            position: relative;
            list-style-type: none !important;
            margin: 0;
            padding: 0px 0px 1px 0px;
            vertical-align: middle;
            &:after {
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIwLjcwNyA1LjI5M2ExIDEgMCAwIDEgMCAxLjQxNGwtMTEgMTFhMSAxIDAgMCAxLTEuNDE0IDBsLTUtNWExIDEgMCAxIDEgMS40MTQtMS40MTRMOSAxNS41ODYgMTkuMjkzIDUuMjkzYTEgMSAwIDAgMSAxLjQxNCAwWiIgZmlsbD0iI2Y3NmIxNSI+PC9wYXRoPjwvc3ZnPg==');
                @apply absolute top-1/2 content-[''] bg-no-repeat w-lg h-lg -translate-y-1/2 -left-6 w-[16px] h-[16px];
            }
        }
    }

    .hs-fieldtype-select,
    .hs-phone {
        &.hs-fieldtype-phonenumber,
        &.hs-fieldtype-text {
            .input {
                &::after {
                    @apply hidden;
                }
            }
        }
    }

    fieldset {
        @apply grid gap-0 xl:gap-md;
        &.form-columns-1 {
            @apply xl:grid-cols-1;
        }
        &.form-columns-2 {
            @apply xl:grid-cols-2 gap-md;
        }
        &.form-columns-3 {
            @apply xl:grid-cols-3;
        }
        &.form-columns-4 {
            @apply xl:grid-cols-4;
        }
        @apply grid-cols-1;

        .hs-dependent-field {
            @apply flex flex-col gap-y-md;
        }

        .hs-phone.hs-fieldtype-text,
        .hs-phone.hs-fieldtype-phonenumber {
            .input {
                &::before {
                    @apply top-sm right-1/2 mr-md max-md:right-0 max-md:mr-sm !important;
                }
                select {
                    @apply pr-xl;
                }
            }
            .hs-fieldtype-intl-phone {
                @apply grid grid-cols-1 lg:grid-cols-2 gap-md;

                &.hs-input {
                    select,
                    input {
                        @apply w-auto !important;
                    }
                }
            }
        }
    }

    .hs-richtext {
        @apply text-16;
        p,
        span {
            @apply text-16 font-base text-white !important;
        }
        p:last-of-type {
            @apply mb-0;
        }
    }

    .hs-error-msgs {
        @apply p-0 mb-0 !important;
        li {
            @apply list-none !important;
            label.hs-error-msg {
                @apply text-14 font-normal text-primary-3 p-0 pt-sm mb-0 !important;
            }
        }
    }

    &.no-border {
        input:not([type='submit']) {
            @apply border-0;
        }
    }
}
