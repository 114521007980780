@layer utilities {
    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .translate-middle {
        @apply -translate-y-1/2 -translate-x-1/2 top-2/4 left-2/4;
    }

    .translate-middle-x {
        @apply -translate-x-1/2 left-2/4;
    }

    .translate-middle-y {
        @apply -translate-y-1/2 top-2/4;
    }

    .overlay-link::after {
        @apply inset-0 absolute z-1;
        content: '';
    }

    /* Disable sticky in the WP editor */
    .wp-block-post-content {
        .sticky {
            z-index: 100;
            position: relative;
        }
    }

    .slide-in-over {
        position: relative;
        z-index: 100;
    }

    .translate-x-1\/2-container {
        --tw-translate-x: calc(theme('screens.xl') / 2);
        @apply transform;
    }

    .-translate-x-1\/2-container {
        --tw-translate-x: calc(theme('screens.xl') / 2 * -1);
        @apply transform;
    }

    .translate-x-1\/4-container {
        --tw-translate-x: calc(theme('screens.xl') / 2 / 2);
        @apply transform;
    }

    .-translate-x-1\/4-container {
        --tw-translate-x: calc(theme('screens.xl') / 2 / 2 * -1);
        @apply transform;
    }

    .current-fill {
        path,
        rect,
        circle,
        polygon {
            fill: currentColor;
        }
    }

    .current-stroke {
        path,
        rect,
        circle,
        polygon {
            stroke: currentColor;
        }
    }

    *[class*='text-truncate-'] {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }

    .text-truncate-1 {
        -webkit-line-clamp: 1;
    }
    .text-truncate-2 {
        -webkit-line-clamp: 2;
    }
    .text-truncate-3 {
        -webkit-line-clamp: 3;
    }
    .text-truncate-4 {
        -webkit-line-clamp: 4;
    }

    .text-truncate-13 {
        -webkit-line-clamp: 13;
    }

    .gradient-box {
        @apply rounded-md;
        background: linear-gradient(#191919, #191919) padding-box, linear-gradient(180deg, #474747 0%, #000000 100%) border-box;
        border: 2px solid transparent;
        transition: border-color 0.2s ease-in-out;
        .body {
            a {
                z-index: 10;
                display: inline;
                position: relative;
            }
        }
        &:hover {
            border-color: #474747 !important;
            transition: border-color 0.2s ease-in-out;
        }
    }

    .text-fade-out {
        -webkit-mask-image: linear-gradient(180deg, #000 50%, transparent);
    }
    .hide-text-fade-out {
        -webkit-mask-image: none !important;
    }
}
