*[id] {
    scroll-margin-top: 100px;
}

@layer base {
    body {
        @apply text-18 text-white bg-black leading-normal;
        font-family: 'DM Sans', 'Helvetica Neue', 'Arial', sans-serif !important;
    }

    h1,
    .h1 {
        @apply text-h1 mb-xl;
    }

    h2,
    .h2 {
        @apply text-h2 mb-md mt-md;
    }

    h3,
    .h3 {
        @apply text-h3 mb-md mt-md;
    }

    h4,
    .h4 {
        @apply text-h4 mb-md mt-md;
    }

    h5,
    .h5 {
        @apply text-h5 mb-md mt-md;
    }

    h6,
    .h6 {
        @apply text-h6 mb-md mt-md;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-bold leading-tight;
        letter-spacing: -0.05em;
        &:first-child {
            @apply mt-0;
        }
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        &.rich-text {
            @apply font-bold leading-tight;
            &:first-child {
                @apply mt-0;
            }
        }
    }

    sub,
    sup {
        @apply text-14 !important;
    }

    .d1 {
        @apply text-d1 mb-0;
    }

    .d2 {
        @apply text-d2 mb-0;
    }

    .d3 {
        @apply text-d3 mb-0;
    }

    small,
    .small {
        @apply text-18;
    }

    a {
        @apply focus:outline-none focus:shadow-none !important;
        &:not(.btn, [class*='text-'], .button, .ic-button-link, .ab-item) {
            @apply hover:underline focus:outline-none text-orange-11 hover:text-primary-1 inline;
        }
    }

    p,
    ul.wp-block-list,
    ol.wp-block-list,
    .wp-block-rank-math-toc-block ul,
    .wp-block-rank-math-toc-block ol {
        @apply mb-md;
        .badge {
            &:first-of-type {
                @apply ml-0;
            }
        }
        ul,
        ol {
            @apply mb-0 mt-sm !important;
        }
    }

    ul.wp-block-list,
    .wp-block-rank-math-toc-block ul,
    .mce-content-body ul {
        @apply pl-lg flex flex-col gap-sm;
        & > li {
            @apply list-disc;
        }
    }

    ol.wp-block-list,
    .wp-block-rank-math-toc-block ol,
    .mce-content-body ol {
        counter-reset: item !important;
        @apply flex flex-col gap-sm;
        & > li {
            @apply m-0 pl-lg list-none relative;
            counter-increment: item !important;
            &:before {
                @apply absolute top-0 left-0 w-lg font-bold;
                content: counter(item) '.' !important;
                counter-increment: auto !important;
            }
        }
    }

    blockquote {
        @apply text-h3;
        section {
            @apply relative inline ml-md;
            &::before {
                content: '\201C';
                position: absolute;
                top: 0px;
                left: -1rem;
            }
            &::after {
                content: '\201D';
                position: absolute;
                bottom: 0px;
                right: -1rem;
            }
        }
        footer {
            @apply text-20;
        }
    }

    pre {
        @apply border-l-5 border-slate-11 bg-white p-lg mb-md;
        code {
            @apply text-16 bg-transparent text-black p-0 m-0 border-0 rounded-none;
        }
    }

    code {
        @apply mx-xs px-[2px] py-[1px] bg-slate-3 text-primary-1 text-16;
    }
}

/* TinyMCE typography fixes. CSS variables does not work here, so you need to use tailwind values */
.mce-content-body {
    ul {
        @apply pl-6 flex flex-col gap-3;
        & > li {
            @apply list-disc;
        }
    }
    ol {
        counter-reset: item !important;
        @apply flex flex-col gap-3 pl-3;
        & > li {
            @apply m-0 pl-5 list-none relative;
            counter-increment: item !important;
            &:before {
                @apply absolute top-0 left-0 w-8 font-bold;
                content: counter(item) '.' !important;
                counter-increment: auto !important;
            }
        }
    }
}

/* PDF generation typography. Only used by the API server to generate PDF's */
.pdf-body {
    ul {
        @apply mb-md gap-sm pl-lg flex flex-col !important;
        & > li {
            @apply list-disc;
        }
    }
    ol {
        counter-reset: item !important;
        @apply flex flex-col gap-sm pl-sm mb-md !important;
        & > li {
            @apply m-0 pl-lg list-none relative;
            counter-increment: item !important;
            &:before {
                @apply absolute top-0 left-0 w-lg font-bold;
                content: counter(item) '.' !important;
                counter-increment: auto !important;
            }
        }
    }
    .dotted-list {
        @apply gap-xl-2 p-0 m-0 !important;
        .dotted-list-item {
            @apply list-none relative text-20 !important;
            .text {
                @apply z-1 bg-black  pr-sm font-bold;
            }
            .dots {
                @apply grow;
            }
            .number {
                @apply text-right z-1 bg-black pl-sm flex flex-col leading-normal font-bold;
            }
            &:before {
                @apply border-b-3 border-white absolute left-0 -top-2 border-dotted content-[''] w-full h-full;
            }
        }
    }
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        @apply font-bold leading-tight;
        letter-spacing: -0.05em;
        &:first-child {
            @apply mt-0;
        }
    }
    h1,
    .h1 {
        @apply text-h3 mt-xl mb-md;
    }

    h2,
    .h2 {
        @apply text-h4 mt-xl mb-md;
    }

    h3,
    .h3 {
        @apply text-h6 mt-xl mb-md;
    }

    h4,
    .h4 {
        @apply text-h6 mt-xl mb-md;
    }

    h5,
    .h5 {
        @apply text-h6 mt-xl mb-md;
    }

    h6,
    .h6 {
        @apply text-h6 mt-xl mb-md;
    }

    p {
        @apply text-16 mb-md;
    }
}

/* Temp fix for blog articles. Added an class (.post-type-post) for editor page.  */

/* to be removed when the blog is updated */
body.single-post,
body.single-product-update,
body.post-type-post,
body.post-type-product-update {
    .wp-block-columns {
        h1,
        .h1 {
            @apply text-h3 mt-xl mb-md;
        }

        h2,
        .h2 {
            @apply text-h4 mt-xl mb-md;
        }

        h3,
        .h3 {
            @apply text-h6 mt-xl mb-md;
        }

        h4,
        .h4 {
            @apply text-h6 mt-xl mb-md;
        }

        h5,
        .h5 {
            @apply text-h6 mt-xl mb-md;
        }

        h6,
        .h6 {
            @apply text-h6 mt-xl mb-md;
        }

        p,
        ul,
        ol {
            @apply mb-xl;
        }
    }
}

.wp-block-article-body-part,
.wp-block-article-header {
    h1,
    .h1 {
        @apply text-h3 mt-xl mb-md;
    }

    h2,
    .h2 {
        @apply text-h4 mt-xl mb-md;
    }

    h3,
    .h3 {
        @apply text-h6 mt-xl mb-md;
    }

    h4,
    .h4 {
        @apply text-h6 mt-xl mb-md;
    }

    h5,
    .h5 {
        @apply text-h6 mt-xl mb-md;
    }

    h6,
    .h6 {
        @apply text-h6 mt-xl mb-md;
    }

    p,
    ul,
    ol {
        @apply mb-xl;
    }
}

.has-drop-cap:not(:focus):first-letter {
    float: left;
    font-size: 6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.68;
    margin: 0.05em 0.2em 10px 0;
    text-transform: uppercase;
    @apply text-primary-2;
}
