.wp-block-content-hub {
    .navigation-tabs {
        .tab {
            @apply hover:text-primary-1 hover:border-primary-1 hover:border-b-2 px-lg py-md font-medium !no-underline text-16;
        }
        &:after {
            @apply border-slate-8 border-b content-[''] absolute bottom-0 left-0 h-[1px] w-full;
        }
    }
}
