section.wp-block-quote-block {
    .inner-block {
        & > .wp-block-columns {
            @apply flex-wrap xl:flex-nowrap !important;
            & > .wp-block-column {
                @apply max-xl:basis-full !important;
            }
        }
    }
}
