#CybotCookiebotDialog {
    @apply bg-slate-2 text-white border-slate-8 border-1 !important;
}

#CybotCookiebotDialog *,
#CybotCookiebotDialogBodyUnderlay * {
    @apply font-base !important;
}

#CybotCookiebotDialogPoweredbyCybot {
    svg {
        path {
            @apply fill-white !important;
        }
    }
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    @apply text-primary-1 !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    @apply bg-primary-1 text-white border-primary-1 !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    @apply border-b-primary-1 !important;
}

#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    @apply text-white border-primary-1 !important;
    border-radius: 50px !important;
    height: 44px !important;
    padding: 0.5rem 1.25rem !important;
    width: auto !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
    @apply text-white leading-6 tracking-normal !important;
}

#CybotCookiebotDialogNav {
    @apply border-b-0 !important;
}

.CybotCookiebotFader {
    @apply hidden;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a,
#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a,
#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink,
#CybotCookiebotDialogDetailBodyContentTextAbout a {
    @apply text-primary-1 !important;
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover,
#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab:hover,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
    @apply text-primary-1 !important;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABContainerToggleHide,
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABContainerToggleShow,
#CybotCookiebotDialog a:after,
#CybotCookiebotDialog a:before,
#CybotCookiebotDialog button:after,
#CybotCookiebotDialog button:before {
    @apply border-primary-1 !important;
}

#CybotCookiebotDialog a:hover:after,
#CybotCookiebotDialog a:hover:before,
#CybotCookiebotDialog button:hover:after,
#CybotCookiebotDialog button:hover:before {
    @apply border-primary-1 !important;
}

#CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount {
    @apply text-slate-2 bg-slate-11 !important;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount) {
    @apply text-white !important;
}

#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink {
    @apply text-slate-11 !important;
}
