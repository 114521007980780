.wp-block-acf-pricing-card {
    @apply h-full;
    .acf-block-component {
        @apply h-full;
        > div {
            @apply h-full;
            .acf-block-preview {
                @apply h-full;
                .inner-blocks {
                    @apply h-full;
                    .block-editor-inner-blocks {
                        @apply h-full;
                        > .block-editor-block-list__layout {
                            @apply h-full flex flex-col;
                        }
                    }
                }
            }
        }
    }
}
