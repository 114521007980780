figure {
    &.wp-block-table {
        @apply max-md:overflow-x-scroll overflow-y-auto break-keep relative pt-xl md:pt-0;
        -webkit-overflow-scrolling: touch;

        &:before {
            content: 'Swipe to see more →';
            @apply absolute text-18 italic left-0 block md:hidden top-0;
        }

        table {
            @apply w-max md:w-full max-md:inline-block !important;
            thead {
                border-bottom: 1px solid;
            }
        }
        figcaption {
            @apply !text-16;
        }
    }
    &.is-style-stripes {
        border-bottom-width: 0px !important;
        table {
            tbody {
                tr {
                    &:nth-child(odd) {
                        @apply bg-slate-8 !important;
                    }
                }
            }
        }
    }
    &.is-style-bordered {
        table {
            td,
            th {
                @apply border-1 !important;
            }
        }
    }

    &.is-style-bordered-stripes {
        table {
            td,
            th {
                @apply border-1 !important;
            }
            tbody {
                tr {
                    &:nth-child(odd) {
                        @apply bg-slate-8 !important;
                    }
                }
            }
        }
    }

    &.is-style-data-table {
        border-bottom-width: 0px !important;
        table {
            tbody {
                tr {
                    &:nth-child(odd) {
                        @apply bg-slate-8 !important;
                    }
                }
            }
        }
    }

    table {
        @apply mb-lg w-full text-[14px];

        tr {
            td,
            th {
                @apply px-md py-sm text-left border-0 !important;
            }
            th {
                &.has-text-align-center {
                    @apply text-center !important;
                }
                &.has-text-align-right {
                    @apply text-right !important;
                }
            }
        }

        /* &.black {
            @apply text-black;
            &.table-border {
                td,
                th {
                    @apply border border-slate-8;
                }
            }
            tbody {
                tr {
                    &:nth-child(odd) {
                        @apply bg-slate-11;
                    }
                }
            }
        } */
    }
}

.dataTables_info,
.dataTables_paginate {
    @apply text-[14px];
}

table.dataTable.dtr-column {
    @apply border-spacing-y-sm  !important;

    &.no-footer {
        @apply border-0  !important;
    }

    &.table-bg-black {
        tr {
            &.odd {
                td:not(.dtr-control) {
                    @apply bg-black border-1 border-black !important;
                }
            }
        }
    }

    &.table-bg-slate-2 {
        tr {
            &.odd {
                td:not(.dtr-control) {
                    @apply bg-slate-2 border-1 border-slate-2 !important;
                }
            }
        }
    }

    tr {
        .first {
            @apply p-0 whitespace-normal box-border min-w-[36px] w-[48px] pr-sm !important;
            &:before {
                @apply hidden !important;
            }
            div {
                @apply relative box-border right-0 m-0 bg-transparent border-1 rounded-sm border-primary-1 font-bold text-h5 shadow-none text-white bg-no-repeat aspect-square;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
                height: 38px !important;
                background-position: 50% 50% !important;
                width: 38px !important;
                display: block !important;
            }
            &.th {
                div {
                    @apply border-0 bg-none !important;
                }
            }
        }
    }

    & > thead {
        & > tr {
            th {
                @apply pr-xl border-0 !important;
            }
        }
    }
    & > tbody {
        & > tr.child {
            td.child {
                @apply p-0 !important;
                .details {
                    @apply bg-black border border-slate-8 p-xl grid lg:grid-cols-6 rounded-sm gap-x-md !important;
                    ul.dtr-details {
                        @apply lg:col-span-5 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-md auto-rows-min !important;
                        li {
                            @apply list-none flex flex-col basis-1/4 p-0 border-0 whitespace-normal !important;
                            .dtr-title {
                                @apply text-white whitespace-normal;
                                .checkbox {
                                    @apply pointer-events-none;
                                    span:before {
                                        @apply hidden;
                                    }
                                    label span {
                                        @apply text-white !important;
                                    }
                                }
                            }
                            .dtr-data {
                                @apply text-slate-11;
                            }
                        }
                    }
                    .actions {
                        @apply text-18 whitespace-normal flex flex-row justify-end;
                        .button {
                            @apply self-end !important;
                            .btn {
                                @apply mr-0 !important;
                            }
                        }
                    }
                }
            }
        }
        tr {
            @apply bg-none cursor-pointer !important;
            &.parent {
                & > td.dtr-control {
                    div {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3E%3Cpolyline points='18 15 12 9 6 15'%3E%3C/polyline%3E%3C/svg%3E");
                    }
                }
            }
            td {
                @apply text-12 !important;
                &.td-0 {
                    @apply rounded-tl-sm rounded-bl-sm !important;
                }
                &:nth-last-child(-n + 1 of td:not([style*='display: none'])) {
                    @apply rounded-tr-sm rounded-br-sm !important;
                }
            }

            &.even {
                td:not(.dtr-control) {
                    @apply border-t-1 border-b-1 border-slate-8 !important;
                    &.td-0 {
                        @apply border-l-1 !important;
                    }
                    &:nth-last-child(-n + 1 of td:not([style*='display: none'])) {
                        @apply border-r-1 !important;
                    }
                }
            }
        }
    }
}
div.dtr-modal div.dtr-modal-display {
    @apply bg-black !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    @apply bg-black text-white bg-none border border-slate-8 rounded-sm !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    @apply bg-black text-white bg-none border border-slate-8 rounded-sm !important;
}
