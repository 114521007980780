figure {
    &:not(.is-resized) {
        img {
            @apply w-full h-auto;
        }
    }
    &.is-resized {
        img {
            /* @apply h-auto w-auto !important; */
        }
    }
}

figure.wp-block-image {
    @apply mb-md;
}

body.single-post,
body.post-type-post {
    .wp-block-columns {
        figure.wp-block-image {
            @apply lg:!my-xl my-0;
        }
    }
}
.has-light-color {
    svg {
        path {
            fill: var(--wp--preset--color--light) !important;
        }
    }
    div[class*='components-'] {
        svg {
            path {
                fill: currentColor !important;
            }
        }
    }
}

.has-dark-color {
    svg {
        path {
            fill: var(--wp--preset--color--dark) !important;
        }
    }
}

.alignright,
.alignleft {
    margin: 0px !important;
}

figcaption {
    @apply text-slate-11 italic;
}

.is-style-rounded-sm {
    img,
    figure {
        @apply rounded-sm overflow-hidden !important;
    }
}

.is-style-rounded-md {
    img,
    video {
        @apply rounded-md overflow-hidden !important;
    }
}

.is-style-rounded-lg {
    img,
    video {
        @apply rounded-lg overflow-hidden !important;
    }
}

.is-style-rounded-full {
    img,
    video {
        @apply rounded-full overflow-hidden !important;
    }
}

.wistia-video div[data-handle='bigPlayButton'] .w-bpb-wrapper {
    @apply rounded-md !important;
}
